import { useEffect, useState } from 'react'
import Textfield from '../elements/textfield'
import { helpText } from '../tooltip/helpText';
import parse from 'html-react-parser';

const ResultsForm = () => {

    // Fields

    const [altFT, setAltFT] = useState("")
    const [volFT, setVolFT] = useState("")
    const [cabEncReq, setCabEncReq] = useState("")
    const [cylSize, setCylSize] = useState("")
    const [numOfCyl, setNumOfCyl] = useState("")
    const [cylFP, setCylFP] = useState("")
    const [emtType, setEmtType] = useState("")
    const [estFinalO, setEstFinalO] = useState("")
    const [actEstDist, setActEstDist] = useState("")
    const [sfa, setSfa] = useState("")

    useEffect(() => {
        if (localStorage.getItem("v-form-results")) {
            let results = localStorage.getItem("v-form-results");
            results = JSON.parse(results)

            setAltFT(results.altFT)
            setVolFT(results.volFT)
            setCabEncReq((results.cabEncReq == 1) ? "Yes" : "No")
            setCylSize(results.cylSize)
            setNumOfCyl(results.numOfCyl)
            setCylFP(results.cylFP)
            setEmtType(results.emtType)
            setEstFinalO(results.estFinalO)
            setActEstDist(results.actEstDist)
            setSfa(results.design_considerations)
        }
    }, [])

    return <div className="form-container bare">
        <div className="space-1"></div>
        <div className="input-group">
            <Textfield help={parse(helpText.altFT)} value={altFT} onChange={setAltFT} disabled required label="Altitude (FT)" placeholder="1100" />
            <Textfield help={parse(helpText.volFT)} value={volFT} onChange={setVolFT} disabled required label={<>Volume &#40;FT&sup3;&#41;</>} placeholder="110" />
            <Textfield help={parse(helpText.cabEncReq)} value={cabEncReq} onChange={setCabEncReq} disabled required label="Cabinet Enclosure Req'd" placeholder="Yes" />
        </div>
        <div className="space-2"></div>
        <div className="input-group">
            <Textfield help={parse(helpText.cylSize)} value={cylSize} onChange={setCylSize} disabled required label="Cylinder Size" placeholder="49L" />
            <Textfield help={parse(helpText.numOfCyl)} value={numOfCyl} onChange={setNumOfCyl} disabled required label="Number of cylinders" placeholder="1" />
            <Textfield help={parse(helpText.cylFP)} value={cylFP} onChange={setCylFP} disabled required label="Cylinder fill pressure (PSI)" placeholder="1500" />
        </div>
        <div className="space-2"></div>
        <div className="input-group">
            <Textfield help={parse(helpText.emtType)} value={emtType} onChange={setEmtType} disabled required label="Emitter Type" placeholder="(1) 3/8 Emiiter @ 40PSI" />
            <Textfield help={parse(helpText.estFinalO)} value={estFinalO} onChange={setEstFinalO} disabled required label={<>Estimated Final 0<sub>2</sub></>} placeholder="4.20" />
            <Textfield help={parse(helpText.actEstDist)} value={actEstDist} onChange={setActEstDist} disabled required label="Act Est Discharge Time (Min)" placeholder="1.460" />
        </div>
        <div className="space-1"></div>
        {sfa &&
            <div className="sfa-box">
                {parse(sfa)}
            </div>
        }
    </div>
}

export default ResultsForm