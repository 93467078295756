import axios from 'axios'
import fileExport from '../../../img/awesome-file-export.svg'
import ionicMDPrint from '../../../img/ionic-md-print.svg'
import zocialEmail from '../../../img/zocial-email.svg'
import AddOption from './addOption'
import { vUrl } from '../../../v500'

const AdditionalOptions = ({ disabled, emailClick }) => {

    const results = JSON.parse(localStorage.getItem("v-form-results"))

    return <div className="add-opt">
        <p>
            SUBMIT YOUR INFORMATION ABOVE TO UNLOCK ADDITIONAL OPTIONS
        </p>
        <div className="add-options">
            <AddOption icon={fileExport} label="Export" to={vUrl + "/pdf/generate?id=" + results.id} disabled={disabled} />
            <AddOption icon={ionicMDPrint} label="Print" to={"/pdf/view/" + results.id} disabled={disabled} />
            <AddOption icon={zocialEmail} label="Email" disabled={disabled} onClick={emailClick} />
        </div>
    </div>
}

export default AdditionalOptions