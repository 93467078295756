import Tooltip from '../tooltip'
import Input from 'react-phone-number-input/input'

const Textfield = ({label=<></>, placeholder, type="text", value="", onChange, required=false, help=false, disabled=false, phone}) => {
    if(!phone) {
        return <div className="input-container">
            <div className={"input-label"}>
                <span className="input-label-text">{label}</span>
                {required ? <span className="required"> *</span> : <></>}
                {help ? <Tooltip message={help} /> : <></>}
            </div>
            <input
                type={type}
                className={(disabled) ? "textfield disabled" : "textfield"}
                placeholder={disabled ? "" : placeholder} 
                value={value} 
                disabled={disabled}
                required={required}
                onChange={e => onChange(e.target.value)}/>
        </div>
    } else {
        return <div className="input-container phone">
            <div className={"input-label"}>
                <span className="input-label-text">{label}</span>
                {required ? <span className="required"> *</span> : <></>}
                {help ? <Tooltip message={help} /> : <></>}
            </div>
            <Input
                country="US"
                value={value}
                placeholder={disabled ? "" : placeholder} 
                maxLength="14"
                disabled={disabled}
                required={required}
                onChange={onChange}/>
        </div>
     }
}

export default Textfield;