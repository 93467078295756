import Header from '../components/layout/header'
import Footer from '../components/layout/footer'
import '../styles/PDF.scss'

const PDFTemplate = ({children}) => {
    return <div className="pdf-layout">
        <Header pdf/>
        {children}
        <Footer pdf/>
    </div>
}

export default PDFTemplate