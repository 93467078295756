import { useState } from 'react'
import Textfield from './elements/textfield'
import Button from './elements/button'
import parse from 'html-react-parser';
import axios from "axios";
import {vUrl} from '../v500';
import { useSnackbar } from 'notistack';
import ModalContext from '../context/modalContextProvider';
import {useContext} from 'react';

const ShareEmailForm = ({id}) => {

    const [emailList, setEmailList] = useState();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const {hideModal} = useContext(ModalContext);

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if(emailList!="") {

                const emailForm = new FormData();

                emailForm.append('id', id);
                emailForm.append('emailTo', emailList);
                
                await axios({
                    method: "post",
                    url: vUrl + '/send-email',
                    data: emailForm,
                    headers: { "Content-Type": "multipart/form-data" },
                }) 

                enqueueSnackbar('Configuration has been shared to the email(s) you provided.', {variant: "success"});

                setEmailList("");
                hideModal();
            } else {
                enqueueSnackbar('Please complete all required fields.', {variant: "error"});
            }
        } catch (e) {
            enqueueSnackbar('An fatal error has occured. Please contact us.', {variant: "error"});
        }
    }

    return <form onSubmit={handleSubmit}>
        <h2>Share your configuration</h2>
        <p>
            Enter one or more emails separated by commas to share you configuration.
        </p>
        <div className="form-container bare">
        <div className="space-1"></div>
        <div className="input-group">
            <Textfield value={emailList} onChange={setEmailList} required label="Email(s)" placeholder="john.doe@example.com, jane.doe@example.com" />
        </div>
        <div className="btn-group">
            <Button type="solid" label="Submit" />
        </div>
        </div>
    </form>
}

export default ShareEmailForm