export const helpText = {
    altFT: "Round altitude up to the nearest 100 ft interval.",
    volFT: "Round volume of protected space up to the nearest 100 ft&sup3; interval. It is acceptable to subtract, non-permeable volume from the protected space (columns, etc.). Example: actual protected volume 1150 ft&sup3; round to 1200 ft&sup3;",
    cabEncReq: "This selection is used to determine the correct part number for your request; it has no influence on the system design results.",
    cylFP: "Cylinders are shipped at standard fill pressures depending on the size. It is the responsibility of the contractor to bleed the cylinders to the pressure stated in the results to ensure proper discharge times and 0<sub>2</sub> levels.",
    cylSize: "For the V500 systems there are 2 cylinder sizes, 80L and 49L.  49L cylinders are only available for V500 systems and are not available for other system designs (V1000, V1500, V2000 and others).",
    numOfCyl: "The number of cylinders is determined based on the cylinder size and fill pressure. Victaulic has reviewed the optimal design for the altitude and volume combinations to be as close as possible to Victaulic recommendations, but the design is always at the discretion of the Engineer of Record and Authority Having Jurisdiction.",
    emtType: "There are 2 types of emitters, 5/8th and 3/8th emitters.  The V500 system will come with the correct transducer set pressure for the design depending on emitter.  V500 is only appropriate for Class A combustible designs.",
    estFinalO: "All designs do not fall entirely within the Victaulic recommendations due to small volumes, limited emitter offerings, and limited cylinder sizes.  It is at the discretion of the Engineer of Record and the Authority Having Jurisdiction as to the appropriateness of the predicted oxygen levels in the protected space.",
    actEstDist: "All designs do not fall entirely within the Victaulic recommendations due to small volumes, limited emitter offerings, and limited cylinder sizes.  It is at the discretion of the Engineer of Record and the Authority Having Jurisdiction as to the appropriateness of the predicted discharge time for each individual scenario."
}