import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ModalContext from './context/modalContextProvider';
import Home from './pages';
import Results from './pages/results';
import ResultsPDF from './pages/results-pdf';
import NotFound from './pages/404';
import { SnackbarProvider } from 'notistack';

const AppRoutes = () => {
    const [active, setActive] = useState("")

    const showModal = (e) => { setActive("active"); }
    const hideModal = () => { setActive("") }

    return <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <ModalContext.Provider value={{ active, showModal, hideModal }}>
            <BrowserRouter basename='/app'>
                <Routes>
                    <Route exact path="/" element={<Home />}></Route>
                    <Route exact path="/results" element={<Results />}></Route>
                    <Route exact path="/pdf" element={<ResultsPDF />}></Route>
                    <Route exact path="*" element={<NotFound />}></Route>
                </Routes>
            </BrowserRouter>
        </ModalContext.Provider>
    </SnackbarProvider>
}

function App() {
    return <AppRoutes />
}

export default App;
