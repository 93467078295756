import PDFTemplate from '../layouts/PDFTemplate'
import '../styles/PDF.scss'

const ResultsPDF = () => {
    return <PDFTemplate>
        <div className="pdf-content">
            <div className="vic-part pdf">Victaulic Part #:S800955c01</div>
            <table className="pdf-table">
                <tr>
                    <th>Altitude (FT)</th>
                    <th>Project Name</th>
                </tr>
                <tr>
                    <td>Alfreds Futterkiste</td>
                    <td>Maria Anders</td>
                </tr>
                <tr>
                    <th>Volume (FT)</th>
                    <th>First Name</th>
                </tr>
                <tr>
                    <td>Centro comercial Moctezuma</td>
                    <td>Francisco Chang</td>
                </tr>
                <tr>
                    <th>Volume (FT)</th>
                    <th>First Name</th>
                </tr>
                <tr>
                    <td>Centro comercial Moctezuma</td>
                    <td>Francisco Chang</td>
                </tr>
                <tr>
                    <th>Volume (FT)</th>
                    <th>First Name</th>
                </tr>
                <tr>
                    <td>Centro comercial Moctezuma</td>
                    <td>Francisco Chang</td>
                </tr>
                <tr>
                    <th>Volume (FT)</th>
                    <th>First Name</th>
                </tr>
                <tr>
                    <td>Centro comercial Moctezuma</td>
                    <td>Francisco Chang</td>
                </tr>
                <tr>
                    <th>Volume (FT)</th>
                    <th>First Name</th>
                </tr>
                <tr>
                    <td>Centro comercial Moctezuma</td>
                    <td>Francisco Chang</td>
                </tr>
                <tr>
                    <th>Volume (FT)</th>
                </tr>
                <tr>
                    <td>Centro comercial Moctezuma</td>
                </tr>
                <tr>
                    <th>Volume (FT)</th>
                </tr>
                <tr>
                    <td>Centro comercial Moctezuma</td>
                </tr>
            </table>
            <div className="space-1"></div>
            <div className="sfa-box clear">
                <span className="title">Safety Factor Adjustment</span> - For small volume spaces, a 10% safety factor is used by this calculator to help maintain higher oxygen levels. It is the responsibility of the Engineer of Record to review the suitability of the safety factor used for the hazard and materials present, along with the oxygen levels that may result, and safeguards required to protect personnel.
            </div>
            <div className="space-4"></div>
            <p>
                Component selection is based solely on your inputs of the specific parameters of your project (such as altitude, cubic feet of coverage, need for system enclosure, etc.). Victaulic does not review your project parameters, design, testing or installation of your selected components. The components offered with this configurator are offered on an "as is" and "as available" basis, subject to standard Victaulic warrantee terms.
            </p>
        </div>
    </PDFTemplate>
}

export default ResultsPDF