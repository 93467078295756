import Header from "../components/layout/header"
import Footer from "../components/layout/footer"
import Toolbar from "../components/layout/toolbar"

const Layout = ({children, toolbar}) => {

    return <div className="layout">
        <Header />
        {toolbar ? <Toolbar /> : <></>}
        <div className="page-container" style={{minHeight: toolbar ? 'calc(100vh - 215px)' : 'calc(100vh - 205px)'}}>
            {children}
        </div>
        <Footer />
    </div>
}

export default Layout