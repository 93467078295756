import { ReactSVG } from "react-svg"

const AddOption = ({icon, label, to, onClick=()=>{}, disabled}) => {
    
    return <a href={(disabled) ? "#" : to} target="_blank" onClick={onClick} className={disabled ? "disabled" : ""}>
                <div className="icon">
                    <ReactSVG src={icon} alt={`${label} Icon`} />
                </div>
                <div className="label">
                    {label}
                </div>
            </a>
}

export default AddOption