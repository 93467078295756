import logo from '../../img/back-btn-icon.svg'
import { useNavigate } from 'react-router-dom'

const Toolbar = () => {
    const navigate = useNavigate();
    return <div className="toolbar">
        <div className="backBtn">
            <button onClick={() => navigate(-1)}><img src={logo} alt="Back Button" /> Back</button>
        </div>
    </div>
}

export default Toolbar