import logo from '../../img/Victaulic_logo.png'

const Footer = ({ pdf }) => {
    return <footer className={pdf ? "pdf" : ""}>
        <div className="fullwidth">
            <p>
                &copy; {(new Date().getFullYear())} Victaulic Company. All rights reserved.
            </p>
            <p>
                The use of the Victaulic Vortex 500 Configurator is subject to the Terms of Use of this website, which you can review at: <a href="https://www.victaulicvortexportal.com/wp-content/uploads/2022/04/victaulic_vortex_portal_terms_of_use_and_end_user_license_agreement.pdf" target="_blank">Terms of Use</a> and <a href="https://www.victaulicvortexportal.com/wp-content/uploads/2022/04/victaulic_vortex_portal_terms_of_use_and_end_user_license_agreement.pdf" target="_blank">End User License Agreement</a>
            </p>
        </div>
    </footer>
}

export default Footer