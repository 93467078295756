import Intro from "../components/forms/intro"
import Layout from '../layouts'

const Home = () => {

    return <Layout>
        <div className="container">
            <div className="space-5"></div>
            {/* <h1>Welcome to the victaulic vortex&trade; 500 component configurator</h1>
            <p className="text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut.
            </p> */}
            <Intro />
        </div>
    </Layout>
}

export default Home