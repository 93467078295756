import axios from "axios"

export const vUrl = process.env.REACT_APP_API_URL;

export const getFormOptions = () => new Promise((resolve) => {
    axios.get(vUrl + '/form-options').then((e) => {
        resolve(e)
    })
})

export const parseOptions = (data) => {
    let e = [];
    for (var x = 0; x < data.length; x++) {
        e.push({ label: data[x], value: data[x] })
    }
    return e;
}