import '../../../styles/Input.scss'
import Textfield from '../../elements/textfield'
import Button from '../../elements/button'
import AdditionalOptions from './additionalOptions'
import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack';
import axios from 'axios'
import { vUrl } from '../../../v500';
import Modal from '../../modal'
import { useContext } from "react";
import ModalContext from "../../../context/modalContextProvider";
import ShareEmailForm from '../../ShareEmailForm';

const InfoForm = () => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { showModal, hideModal } = useContext(ModalContext)

    // Fields
    const [projName, setProjName] = useState("")
    const [fName, setFName] = useState("")
    const [lName, setLName] = useState("")
    const [compName, setCompName] = useState("")
    const [emailAddr, setEmailAddr] = useState("")
    const [phoneNum, setPhoneNum] = useState("")

    // Toggle Additional Options
    const [disabled, setDisabled] = useState(true);
    const [showBtns, setShowBtns] = useState(true);

    const [reqID, setReqID] = useState();

    useEffect(() => {
        if (localStorage.getItem("v-form-results")) {
            let results = localStorage.getItem("v-form-results");
            results = JSON.parse(results)
            if (results.projName) setProjName(results.projName);
            setCompName(results.compName);
        }
    }, [])

    // Reset form
    const resetForm = () => {
        console.log("reset")
        setProjName("");
        setFName("");
        setLName("");
        setCompName("");
        setEmailAddr("");
        setPhoneNum("");
    }

    // Email validation
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    // Handle submit
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (fName != "" &&
                lName != "" &&
                compName != "" &&
                emailAddr != "" &&
                phoneNum != "") {
                if (validateEmail(emailAddr)) {
                    const form = new FormData();

                    let results = localStorage.getItem("v-form-results");
                    results = JSON.parse(results);

                    form.append('id', results.id);
                    form.append('projName', projName);
                    form.append('fName', fName);
                    form.append('lName', lName);
                    form.append('compName', compName);
                    form.append('emailAddr', emailAddr);
                    form.append('phoneNum', phoneNum);

                    const result = await axios({
                        method: "post",
                        url: vUrl + '/form-submit',
                        data: form,
                        headers: { "Content-Type": "multipart/form-data" },
                    })
                    console.log(result)
                    setDisabled(false)
                    setShowBtns(false)
                    localStorage.removeItem('v-form-values')

                    const emailForm = new FormData();

                    emailForm.append('id', results.id);
                    emailForm.append('email', emailAddr);

                    setReqID(results.id)

                    await axios({
                        method: "post",
                        url: vUrl + '/send-email',
                        data: emailForm,
                        headers: { "Content-Type": "multipart/form-data" },
                    })
                    enqueueSnackbar('Information received. A copy of this configuration has been sent to the provided email.', { variant: "info" });
                } else {
                    enqueueSnackbar('Invalid email address format.', { variant: "error" });
                }

            } else {
                enqueueSnackbar('Please complete all required fields.', { variant: "error" });
            }
        } catch {
            enqueueSnackbar('An fatal error has occured. Please contact us.', { variant: "error" });
        }
    }

    return <div className="form-container compact">
        <Modal>
            <ShareEmailForm id={reqID} />
        </Modal>
        <form onSubmit={handleSubmit}>
            <div className="input-group">
                <Textfield disabled={!disabled} label="Project Name" onChange={setProjName} value={projName} placeholder="Name your project" />
            </div>
            <div className="input-group">
                <Textfield disabled={!disabled} required label="First Name" onChange={setFName} value={fName} placeholder="First name" />
                <Textfield disabled={!disabled} required label="Last Name" onChange={setLName} value={lName} placeholder="Last name" />
            </div>
            <div className="input-group">
                <Textfield disabled={!disabled} required label="Name of your company" onChange={setCompName} value={compName} placeholder="Name of your company" />
            </div>
            <div className="input-group">
                <Textfield type="email" disabled={!disabled} required label="Email Address" onChange={setEmailAddr} value={emailAddr} placeholder="Enter your email address" />
                <Textfield disabled={!disabled} required label="Phone Number" onChange={setPhoneNum} value={phoneNum} placeholder="___-___-____" phone />
            </div>
            {(showBtns) ? <div className="btn-group inline">
                <Button type="solid" label="Submit" />
                <Button type="outline" label="Start Over" onClick={resetForm} dontSubmit />
            </div> : <></>}
        </form>
        <AdditionalOptions disabled={disabled} emailClick={showModal} />
    </div>
}

export default InfoForm