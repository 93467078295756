import { useNavigate } from "react-router-dom";

const Button = ({type, label, onClick=()=>{}, to=false, dontSubmit}) => {
    const navigate = useNavigate();
    if(to) {
        return <button className={type} onClick={() => {navigate(to); onClick()}}>{label}</button>
    } else {
        return <button className={type} onClick={onClick} type={dontSubmit ? "button" : "submit"}>{label}</button>
    }
}

export default Button