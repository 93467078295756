import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "../elements/select"
import Textfield from '../elements/textfield'
import '../../styles/Input.scss';
import Button from "../elements/button";
import { useContext } from "react";
import ModalContext from "../../context/modalContextProvider";
import { getFormOptions, parseOptions, vUrl } from '../../v500';
import { useSnackbar } from 'notistack';
import { helpText } from '../tooltip/helpText';
import parse from 'html-react-parser';
import Modal from '../modal'

const Intro = () => {

    const { enqueueSnackbar } = useSnackbar();
    const { showModal, hideModal } = useContext(ModalContext)

    const [dc, setDC] = useState("")

    // Fields

    const [altFT, setAltFT] = useState()
    const [volFT, setVolFT] = useState()
    const [cabEncReq, setCabEncReq] = useState()
    const [projName, setProjName] = useState("")
    const [compName, setCompName] = useState("")
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (altFT !== "" &&
                volFT !== "" &&
                cabEncReq !== "" &&
                compName !== "") {
                const form = new FormData();

                form.append('altFT', altFT.value);
                form.append('volFT', volFT.value);
                form.append('cabEncReq', (cabEncReq.value === "Yes") ? 1 : 0);
                form.append('projName', projName);
                form.append('compName', compName);

                const result = await axios({
                    method: "post",
                    url: vUrl + '/form-result',
                    data: form,
                    headers: { "Content-Type": "multipart/form-data" },
                })

                setDC(String(result.data.design_considerations))

                if (result) {
                    localStorage.setItem("v-form-results", JSON.stringify(result.data));

                    const formValues = {
                        altFT: altFT,
                        volFT: volFT,
                        cabEncReq: cabEncReq,
                        projName: projName,
                        compName: compName,
                    }
                    localStorage.setItem("v-form-values", JSON.stringify(formValues));
                    if (result.data.partNo) {
                        if (result.data.design_considerations) {
                            showModal();
                        } else {
                            navigate('/results');
                        }
                    } else {
                        enqueueSnackbar('Invalid configuration, please select different values.', { variant: "error" });
                    }
                    
                } else {
                    enqueueSnackbar('An fatal error has occured. Please contact us.', { variant: "error" });
                }

            } else {
                enqueueSnackbar('Please complete all required fields.', { variant: "error" });
            }
        } catch {
            enqueueSnackbar('An fatal error has occured. Please contact us.', { variant: "error" });
        }
    }

    const resetForm = () => {
        setAltFT([]);
        setVolFT([]);
        setCabEncReq([]);
        setProjName("");
        setCompName("");
    }

    const [altOpt, setAltOpt] = useState([])
    const [volOpt, setVolOpt] = useState([])
    const [cabOpt, setCabOpt] = useState([])

    useEffect(() => {

        if (localStorage.getItem("v-form-values")) {
            const values = JSON.parse(localStorage.getItem("v-form-values"));
            setAltFT(values.altFT)
            setVolFT(values.volFT)
            setCabEncReq(values.cabEncReq)
            setProjName(values.projName)
            setCompName(values.compName)
        }

        getFormOptions().then((e) => {
            const data = e.data;

            const altOpt = parseOptions(data.altitude)
            setAltOpt(altOpt)
            const volOpt = parseOptions(data.volume)
            setVolOpt(volOpt)
            const cabOpt = parseOptions(data.cabinet_required)
            setCabOpt(cabOpt)
        })
    }, [])

    return <form className="form-container" onSubmit={handleSubmit}>
        <Modal>
            <p>
                {parse(dc)}
            </p>
            <div className="btn-group">
                <Button type="solid" label="Go Back" onClick={hideModal} dontSubmit />
                <Button type="outline" label="Continue" onClick={hideModal} to="/results" />
            </div>
        </Modal>
        <div className="input-group">
            <Select
                required
                help={parse(helpText.altFT)}
                label="Altitude (FT)"
                placeholder="Make a selection"
                value={altFT}
                onChange={setAltFT}
                options={altOpt} />
            <Select
                required
                help={parse(helpText.volFT)}
                label={<>Volume &#40;FT&sup3;&#41;</>}
                placeholder="Make a selection"
                value={volFT}
                onChange={setVolFT}
                options={volOpt} />
            <Select
                required
                help={parse(helpText.cabEncReq)}
                label="Cabinet Enclosure Required"
                placeholder="Make a selection"
                value={cabEncReq}
                onChange={setCabEncReq}
                options={cabOpt} />
        </div>
        <div className="input-group">
            <Textfield label="Project Name" onChange={setProjName} value={projName} placeholder="Name your project" />
        </div>
        <div className="input-group">
            <Textfield required label="Company Name" onChange={setCompName} value={compName} placeholder="Name of your company" />
        </div>
        <div className="btn-group">
            <Button type="solid" label="Continue" />
            <Button dontSubmit type="outline" label="Reset Selections" onClick={resetForm} />
        </div>
    </form>
}

export default Intro