import Layout from '../layouts'
import InfoForm from '../components/forms/infoForm'
import ResultsForm from '../components/forms/resultsForm'
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";

const Results = () => {
    const navigate = useNavigate();
    const [partNo, setPartNo] = useState("")

    useEffect(() => {
        if (!localStorage.getItem("v-form-results")) {
            navigate("/")
        } else {
            let results = localStorage.getItem("v-form-results");
            results = JSON.parse(results)
            setPartNo(results.partNo)
        }
    }, [])

    return <Layout toolbar>
        <div className="space-3"></div>
        <div className="container-fluid flex">
            <div className="col">
                <h1 className="form-header">Your Results</h1>
                <p className="text-center">
                    Victaulic Vortex 500 system part code and configuration based on your inputs.
                </p>
                <div className="space-2"></div>
                <div className="vic-part">Victaulic Part #: {partNo}</div>
                <ResultsForm />
            </div>
            <div className="col">
                <h1 className="form-header">Sharing Options</h1>
                <p className="text-center">
                    We just need a little more information from you.
                </p>
                <div className="space-2"></div>
                <InfoForm />
            </div>
        </div>
    </Layout>
}

export default Results