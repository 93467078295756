import ReactSelect from 'react-select';
import Tooltip from '../tooltip';

// Options object example:

// const options = [
//     {
//         label: "Option 1",
//         value: "opt-1"
//     },
//     {
//         label: "Option 2",
//         value: "opt-2"
//     },
// ]

const Select = ({label, placeholder, onChange, value, options=[{value:"", label:""}], required=false, help=false}) => {

    return <div className="input-container">
        <div className={"input-label"}>
        <span className="input-label-text">{label}</span>
            {required ? <span className="required"> *</span> : <></>}
            {help ? <Tooltip message={help} /> : <></>}
        </div>
        <div className="select-container">
            <ReactSelect 
                placeholder={placeholder}
                defaultValue={""} 
                onChange={onChange} 
                options={options}
                value={value}
                classNamePrefix="react-select"
            />
        </div>
    </div>
}

export default Select