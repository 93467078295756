import { useContext } from "react"
import ModalContext from "../context/modalContextProvider"
import '../styles/Modal.scss'

const Modal = ({children}) => {
    const {active, hideModal} = useContext(ModalContext)
    return <div className={`modal-container ${active}`}>
        <div className="modal-form">
            <div className="close-modal" onClick={hideModal}></div>
            {children}
        </div>
    </div>
}

export default Modal